<template>
  <div>
    <div id="container" :class="[loading && 'overlay overlay-block']">
      <div class="d-flex flex-row justify-content-between my-4">
        <!--begin::Search-->
        <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="../media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            v-model="search"
            class="form-control form-control-solid w-250px ps-15"
            placeholder="Search by Lastname or Email"
          />
        </div>
        <!--end::Search-->
        <!--begin:Add User-->
        <div>
          <!--begin::Add user-->
          <button
            type="button"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#modal_new_application_user"
          >
            <span class="svg-icon svg-icon-1x">
              <inline-svg src="../media/icons/duotune/arrows/arr013.svg" />
            </span>
            Add User
          </button>
          <!--end::Add user-->
        </div>
        <!--end:Add User-->
      </div>

      <div v-if="getItems.length" class="pb-2">
        <!--Row-->
        <div
          class="
            d-flex
            flex-column flex-md-row
            justify-content-around
            border-bottom border-gray-200
          "
          v-for="(item, i) in getItems"
          :key="i"
        >
          <!-- Name -->
          <div class="d-flex align-items-center py-2 w-25-md">
            <div class="d-flex flex-column">
              <p class="m-0 text-gray-900 fw-bold fs-6">
                {{ item.firstName }} {{ item.lastName }}
              </p>
              <p class="m-0">
                <small>{{ item.companyName }}</small>
              </p>
              <p class="m-0">
                <small>Role: {{ item.userRoleName }}</small>
              </p>
            </div>
          </div>
          <!-- End Name -->
          <!-- Email -->
          <div class="d-flex align-items-center w-35-md">
            <a
              :href="'mailto:' + item.email"
              class="text-gray-600 fw-bold text-hover-primary"
            >
              {{ item.email }}
            </a>
          </div>
          <!-- End Email -->
          <!-- Right Section -->
          <div class="d-flex justify-content-between w-40-md">
            <div class="d-flex justify-content-center align-items-center py-2">
              <span v-show="item.emailConfirmed" class="badge badge-success"
                >Email Verified</span
              ><span v-show="!item.emailConfirmed" class="badge badge-danger"
                >Email Not Verified</span
              >
            </div>
            <div class="d-flex justify-content-center align-items-center py-2">
              <span v-show="!item.deleted" class="badge badge-success"
                >Active</span
              ><span v-show="item.deleted" class="badge badge-danger"
                >Deactivated</span
              >
            </div>
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
                py-2
              "
            >
              <div class="d-flex flex-row m-0">
                <router-link
                  :to="{
                    name: 'account-overview',
                    params: {
                      accountid: item.id,
                    },
                  }"
                  class="
                    btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                    me-1
                  "
                >
                  <span class="svg-icon svg-icon-3">
                    <inline-svg
                      src="../media/icons/duotune/art/art005.svg"
                    /> </span
                ></router-link>

                <a
                  v-show="!item.deleted"
                  @click="deleteUser(item.id)"
                  href="#"
                  class="
                    btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                  "
                >
                  <span class="svg-icon svg-icon-3">
                    <inline-svg
                      src="../media/icons/duotune/general/gen027.svg"
                    />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <!-- End Right Section -->
        </div>
        <!-- End Row -->
      </div>

      <!-- Loading -->
      <div
        v-if="loading"
        class="overlay-layer card-rounded bg-dark bg-opacity-5"
      >
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <!-- End Loading -->
    </div>

    <div class="row">
      <div
        class="
          col-sm-12 col-md-5
          d-flex
          align-items-center
          justify-content-center justify-content-md-start
        "
      >
        <div
          v-if="enableItemsPerPageDropdown"
          class="dataTables_length"
          id="kt_customers_table_length"
        >
          <label
            ><select
              name="kt_customers_table_length"
              class="form-select form-select-sm form-select-solid"
              @change="setItemsPerPage"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select></label
          >
        </div>
      </div>
      <div
        class="
          col-sm-12 col-md-7
          d-flex
          align-items-center
          justify-content-center justify-content-md-end
        "
      >
        <el-pagination
          v-model:current-page="pagination.page"
          @current-change="currentPageChange"
          :page-size="pagination.rowsPerPage"
          layout="prev, pager, next"
          :total="pagination.total"
          :hide-on-single-page="true"
          background
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  ref,
  onMounted,
  watch,
  getCurrentInstance,
  PropType,
  ComputedRef,
} from "vue";
import { ApplicationUser } from "@/core/types/UserTypes";

interface IPagination {
  page: number;
  total: number;
  rowsPerPage: number;
}

export default defineComponent({
  name: "lcd-listtable",
  emit: ["current-change", "sort", "items-per-page-change", "delete-user"],
  props: {
    tableData: {
      type: Object as PropType<Array<ApplicationUser>>,
      required: true,
    },
    emptyTableText: { type: String, default: "No data found" },
    loading: { type: Boolean, default: false },
    currentPage: { type: Number, default: 1 },
    enableItemsPerPageDropdown: { type: Boolean, default: true },
    total: { type: Number, default: 0 },
    rowsPerPage: { type: Number, default: 10 },
    order: { type: String, default: "asc" },
    sortLabel: { type: String, default: "" },
  },
  components: {},
  setup(props, { emit }) {
    const data = ref(props.tableData);
    const currentSort = ref<string>("");
    const order = ref(props.order);
    const label = ref(props.sortLabel);
    const pagination = ref<IPagination>({
      page: 1,
      total: props.total,
      rowsPerPage: props.rowsPerPage,
    });

    const vnodeProps = getCurrentInstance()?.vnode.props || {};

    watch(data.value, () => {
      if ("onCurrentChange" in vnodeProps) {
        currentSort.value = label.value + order.value;
      } else {
        pagination.value.total = data.value.length;
      }
    });

    onMounted(() => {
      pagination.value.total = props.total ? props.total : data.value.length;
      pagination.value.rowsPerPage = props.rowsPerPage;
    });

    const getItems: ComputedRef<Array<ApplicationUser>> = computed(() => {
      if ("onCurrentChange" in vnodeProps) {
        return filterUsers.value;
      } else {
        const clone = JSON.parse(JSON.stringify(filterUsers.value));
        const startFrom =
          pagination.value.page * pagination.value.rowsPerPage -
          pagination.value.rowsPerPage;
        return clone.splice(startFrom, pagination.value.rowsPerPage);
      }
    });
    const search = ref("");
    const filterUsers = computed(() => {
      return data.value.filter((user) => {
        return (
          user.lastName.toLowerCase().indexOf(search.value.toLowerCase()) !=
            -1 ||
          user.email.toLowerCase().indexOf(search.value.toLowerCase()) != -1
        );
      });
    });

    const currentPageChange = (val) => {
      if ("onCurrentChange" in vnodeProps) {
        emit("current-change", val);
      } else {
        pagination.value.page = val;
      }
    };

    function deleteUser(id) {
      emit("delete-user", id);
    }

    const setItemsPerPage = (event) => {
      if ("onItemsPerPageChange" in vnodeProps) {
        emit("items-per-page-change", parseInt(event.target.value));
      } else {
        pagination.value.rowsPerPage = parseInt(event.target.value);
      }
    };

    return {
      search,
      filterUsers,
      pagination,
      currentPageChange,
      getItems,
      setItemsPerPage,
      deleteUser,
    };
  },
});
</script>

<style lang="scss">
/* Responsive Code */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768 and up)*/
@media (min-width: 768px) {
  .w-25-md {
    width: 25% !important;
  }
  .w-35-md {
    width: 35% !important;
  }
  .w-40-md {
    width: 40% !important;
  }
}

/* Large devices (desktops, 992 and up)*/
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200 and up)*/
@media (min-width: 1200px) {
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}
</style>
