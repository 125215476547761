
import { defineComponent, ref, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { NewApplicationUser } from "../../../core/types/UserTypes";

export default defineComponent({
  name: "new-application-user-modal",
  props: ["displayModal"],
  components: { Field, Form, ErrorMessage },
  setup() {
    const store = useStore();

    const submitButton = ref<HTMLElement | null>(null);

    const modalRef = ref<null | HTMLFormElement>(null);

    const newApplicationUser = ref<NewApplicationUser>({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      userRole: "",
      activeAccount: true,
      companyName: "",
      jobTitle: "",
    });

    const registration = Yup.object().shape({
      firstName: Yup.string().required().label("First Name"),
      lastName: Yup.string().required().label("Last Name"),
      email: Yup.string().min(4).required().email().label("Email"),
      password: Yup.string().min(6).required().label("Password"),
      confirmPassword: Yup.string()
        .min(6)
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Password Confirmation"),
      userRole: Yup.string().required().label("User Role"),
    });

    const onSubmitRegister = () => {
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // Send login request
      store
        .dispatch(Actions.CREATE_USER, newApplicationUser.value)
        .then(() => {
          Swal.fire({
            text: "Success. The new user has been created.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Continue",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            resetForm();
            modalRef.value?.click();
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    const userRoles = computed(() => {
      if (store.getters.currentUserRole === "SuperAdmin") {
        return [
          { roleName: "Super Admin", roleValue: "SuperAdmin" },
          { roleName: "Snappy Staff", roleValue: "SnappyStaff" },
          { roleName: "Guest", roleValue: "Guest" },
        ];
      }
      return [];
    });

    function closeModal() {
      modalRef.value?.click();
      resetForm();
    }

    function resetForm() {
      newApplicationUser.value = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        userRole: "",
        activeAccount: true,
        companyName: "",
        jobTitle: "",
      };
    }

    return {
      newApplicationUser,
      closeModal,
      resetForm,
      modalRef,
      userRoles,
      registration,
      onSubmitRegister,
      submitButton,
    };
  },
});
